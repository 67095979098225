<template>
    <vs-popup class="memberSelect_popup" title="Choisir membre" :active.sync="popupActive">
                <vs-table v-model="member_select" pagination max-items="9" search :data="member_list" @selected="selectFonc">


            <!-- header table -->
            <template slot="thead">
                <vs-th sort-key="first_name">Nom</vs-th>
                <vs-th sort-key="last_name">Prénom</vs-th>
                <vs-th sort-key="phone">Téléphone</vs-th>
            </template>

            <!--Body table-->
            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].first_name">
                        <span>{{ data[indextr].first_name }}</span>
                    </vs-td>
                    <vs-td :data="data[indextr].last_name">
                        <span>{{ data[indextr].last_name }}</span>
                    </vs-td>                
                    <vs-td :data="data[indextr].phone">
                        <span>{{ data[indextr].phone }}</span>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vs-popup>
</template>

<style lang="scss">
.memberSelect_popup
{

}
</style>

<script>

import Member from '@/database/models/member'

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            member_list:[],
            member_select:undefined,
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        Member.getTabAllMemory((list)=>{
            this.member_list = list
        })
    },
    methods:{
        openPopup( callback )
        {
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback
        },
        selectFonc()
        {
            if(this.callback)
                this.callback(this.member_select)

            //fermeture popup
            this.popupActive = false
        },
    }
}

</script>